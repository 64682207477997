import React, { lazy, Suspense } from 'react';
import LoadingComponent from '../pages/ZycusChat/components/Loader';

const withLazyLoad = (importComponent) => {
  const LazyComponent = lazy(importComponent);

  const LazyLoadedComponent = (props) => (
    <Suspense fallback={<div><LoadingComponent/></div>}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return LazyLoadedComponent;
};

export default withLazyLoad;
