import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import routes from "./routes/index.js";
import React from "react";
import { UserContextProvider } from "./context/userGetContext.js";


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    
  },
});
function App() {


  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
    <BrowserRouter>
      <div className="App">
        <Routes>
          {routes.map((item ,index) =><Route key={index} path={item.path}  element={item.component}></Route> )}
        </Routes> 
      </div>
    </BrowserRouter>
    </UserContextProvider>
    </ThemeProvider>
  );
}

export default App;
