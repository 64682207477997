import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { app } from "@microsoft/teams-js";
import { azureTables, STRINGS_EN, tableActions } from "../constant";
import useTableOperations from "../hooks/useTableOperations";
import { triggerOperation } from "../utils/triggerOpration";

const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};

export const UserContextProvider = ({ children }) => {
  const [userContext, setUserContext] = useState({});
  const [addAccountEmail, setAddAccountEmail] = useState("");
  const [loadingTeamsContext, setLoadingTeamsContext] = useState(true);
  const [errorTeamsContext, setErrorTeamsContext] = useState(null);
  const [theme , setTheme] = useState('default');

  const updateTeamsContext = (data) => {
    const { email, aadObjectId } = data;
    const teamContextData = {
      id: aadObjectId,
      userPrincipalName: email,
      tenant: {
        id: "",
        teamsSku: "",
      },
    };
    setUserContext(teamContextData);
    setLoadingTeamsContext(false);
    setErrorTeamsContext(null);
  };

  const updateAccountEmail = (email) => {
    setAddAccountEmail(email);
  };

  const updateRoasterInfo = async (subEntityId, aadObjectId, email) => {
    if (!aadObjectId || !email) {
      return;
    }
    try {
      const entity = {
        aadObjectId,
        //email,
        subEntityId: subEntityId ? subEntityId : "",
      };
      const response = await triggerOperation(
        azureTables.MERLINASSISTROASTERINFO,
        tableActions.UPSERT,
        entity
      ); // update roaster info

      console.log({ response });
    } catch (error) {
      console.log(error);
    }
  };

  const initializeTeams = useCallback(async () => {
    try {
      await app.initialize();
      const context = await app.getContext();
      const usercontextData = context?.user;
      setUserContext({...usercontextData , fromTeams : true}); // mock
      const subEntityId = context?.page?.subPageId;
      const email =
        context?.user?.displayName || context?.user?.userPrincipalName;
      const aadObjectId = context?.user?.id;

      updateRoasterInfo(subEntityId, aadObjectId, email);
      setLoadingTeamsContext(false);
      setErrorTeamsContext(null);
      setTheme(context?.app?.theme)
      app.notifySuccess();
    } catch (err) {
      console.error("Error initializing Microsoft Teams SDK:", err);
      app.notifyFailure({ message: "Initialization failed" });
      setLoadingTeamsContext(false);
      setErrorTeamsContext(STRINGS_EN.ERROR);
      //  setUserContext(manualData); // TO DO to run in browser enter your details to above manualData
      // app.notifySuccess();
    }
  }, []);
  useEffect(() => {
    if (!Object.keys(userContext).length > 0) {
      initializeTeams();
    }
  }, []);

  console.log({ userContext });
  return (
    <UserContext.Provider
      value={{
        ...userContext,
        errorTeamsContext,
        loadingTeamsContext,
        updateAccountEmail,
        addAccountEmail,
        updateTeamsContext, 
        theme,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
