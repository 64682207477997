import React from "react";
import withLazyLoad from "./withLazyLoad";


const Collaboration = withLazyLoad(()=> import('../pages/Collaboration'))
const  RootNavigator  = withLazyLoad(() => import('../pages/ZycusChat'));
const routes = [
    {
      component: <RootNavigator/> ,
      path: '/',
      title: 'RootNavigator',
    },
    {
      component: <Collaboration/> ,
      path: '/',
      title: 'Collaboration',
    },
    {
      component: <RootNavigator/> ,
      path: '*',
      title: 'RootNavigator',
    },
  ];

  export default routes