import axios from "axios";

async function request(method = "GET", url, options) {
  try {
    const response = await axios({
      method: method,
      url: url,
      headers: options?.headers || {'Content-Type': 'application/json'},
      data: JSON.stringify(options?.payload) || null
    });
    return response?.data;
  } catch (error) {
    throw error ;
  }
}

export default request;
