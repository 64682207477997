import { useCallback, useEffect, useState } from "react";
import request from "../utils/request";

function useTableOperations(tableName, action, entity, key, azureAccountName) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  const triggerOperation = useCallback(async () => {
    if (!tableName || !action || (!entity?.aadObjectId && !entity?.email)) {
      setLoading(false);
      return;
    }

    const tableBaseUrl = process.env.REACT_APP_CRUD_OP_TABLE_BASE_URL;
    const tablePath = process.env.REACT_APP_CRUD_OP_TABLE_PATH;
    const securityCode = process.env.REACT_APP_CRUD_OP_TABLE_SECURITY_CODE;
    const accessKey = process.env.REACT_APP_MAIN_STORAGE_ACC_ACCESS_KEY;
    const accountName = process.env.REACT_APP_MAINSTORAGEACCNAME;

    if (
      !tableBaseUrl ||
      !tablePath ||
      !securityCode ||
      !accessKey ||
      !accountName
    ) {
      setLoading(false);
      setError(new Error("Environment variables are missing"));
      return;
    }

    const keys = {
      partitionKey: entity?.aadObjectId,
      rowKey: entity?.aadObjectId,
    };
    const options = {
      payload: {
        accessKey: key || accessKey,
        table: tableName,
        operation: action,
        accountName: azureAccountName || accountName,
        entity: { ...entity, ...keys },
      },
    };
    const url = `https://${tableBaseUrl}/${tablePath}?code=${securityCode}`;

    try {
      const response = await request("POST", url, options);

      if (
        response?.resp.hasOwnProperty("message") &&
        response?.resp?.message?.status === 500
      ) {
        setLoading(false);
        setError(response?.resp.message);
      } else {
        setLoading(false);
        setData(response?.resp);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, [tableName, action, entity]);

  useEffect(() => {
    triggerOperation();
  }, []);

  return { loading, error, data };
}

export default useTableOperations;
